<template>
  <div class="reg-window">
    <div class="reg-tab-group">
      <div class="reg-tab"
           :class="{ active: isTel == 1 }"
           @click="changeTab(1)">
        手机找回
      </div>
      <div class="reg-tab"
           :class="{ active: isTel == 2 }"
           @click="changeTab(2)">
        邮箱找回
      </div>
    </div>
    <div class="reg-body-group">
      <div class="reg-body">
        <div class="reg-line"
             v-show="step==0">
          <div class="reg-insert" v-if="isTel == 1">
            <div class="reg-attr">注册手机</div>
            <div class="reg-val">
              <el-input type="tel"
                        v-model="user.telPhone"
                        maxlength="11"
                        placeholder="请输入已注册的手机号码" />
            </div>
          </div>
          <div class="reg-insert" v-if="isTel == 2">
            <div class="reg-attr">注册邮箱</div>
            <div class="reg-val">
              <el-input type="tel"
                        v-model="user.email"
                        maxlength="320"
                        placeholder="请输入已注册的邮箱地址" />
            </div>
          </div>
          <div class="reg-insert">
            <div class="reg-attr">{{ isTel == 1 ? "手机验证" : "邮箱验证" }}</div>
            <div class="reg-val">
              <el-input type="tel"
                        v-model="user.verificationCode"
                        @input="codeChange"
                        maxlength="6"
                        placeholder="请输入验证码" />
            </div>
            <div class="reg-space">
              <el-button type="primary"
                         v-if="isShowBtn"
                         :plain="emailDis"
                         :disabled="emailDis"
                         style="width: 100%"
                         @click="verificationCode()">{{emailButton}}</el-button>
              <el-button type="info"
                         v-if="!isShowBtn"
                         :plain="true"
                         :disabled="true"
                         style="width: 100%">{{times}}</el-button>
            </div>
          </div>
        </div>
        <div class="reg-line"
             v-show="step==1">
          <el-form ref="form"
                   :model="user"
                   :rules="rules">
            <el-form-item prop="password">
              <div class="reg-insert m-0">
                <div class="reg-attr">新密码</div>
                <div class="reg-val">
                  <el-input type="password"
                            v-model="user.password"
                            @input="getPwdLevel"
                            minlength="6"
                            placeholder="密码由超过6位字符组成"
                            show-password />
                  <el-progress v-show="pswLevel != 0"
                               :percentage="pswLevel * 20"
                               :color="colors[pswLevel]"
                               :stroke-width="3"
                               :show-text="false" />
                </div>
              </div>
            </el-form-item>
            <el-form-item prop="rePassword">
              <div class="reg-insert m-0">
                <div class="reg-attr">重复密码</div>
                <div class="reg-val">
                  <el-input type="password"
                            v-model="user.rePassword"
                            minlength="6"
                            placeholder="重复密码必须与登录密码一致"
                            show-password />
                </div>
              </div>
            </el-form-item>
          </el-form>
        </div>
        <div class="reg-line"
             style="margin:32px 0 0">
          <el-button type="primary"
                     style="width: 100%;"
                     :disabled="codeValidate"
                     @click="forgetNext()"
                     v-if="step==0">下一步</el-button>
          <el-button type="primary"
                     style="width: 100%;"
                     v-if="step==1"
                     @click="saveAndLogin()">保存并登录</el-button>
        </div>
      </div>
    </div>
    <div class="reg-success"
         v-if="regSuccess">
      <i class="el-icon-check"></i>
      <span>修改密码申请已提交，请等待管理员审核<br />审核结果将以短信或邮件方式通知您</span>
    </div>
    <verification :verify-show="verify.show"
                  @cancel="verificationCancel"
                  @confirm="verificationConfirm"
                  ref="verify" />
  </div>
</template>

<script>
import FrameHeader from 'components/content/frame/header/Header'
import Verification from './Verification'
import { validatePassword } from 'common/validate';

export default {
  name: "Forget",
  components: {
    FrameHeader,
    Verification,
  },
  data () {
    var validateRepeatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.user.password) {
        callback(new Error('与新密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      rules: {
        password: [{ required: true, message: '新密码不能为空', trigger: 'blur' }, { validator: validatePassword, message: '密码（至少8位）需要包含字母或数字或下划线', trigger: 'blur' }],
        rePassword: [{ required: true, message: '重复密码不能为空', trigger: 'blur' }, { validator: validateRepeatePass, trigger: 'blur' }],
      },
      appName: '蓝色星球可视化数据大屏平台',
      step: 0,
      isTel: 1,
      user: {
        account: "",
        password: "",
        rePassword: "",
        telPhone: "",
        email: "",
        verificationCode: "",
      },
      verificationCodeUUID: "",
      emailDis: false,
      emailButton: "发送验证码",
      times: 60,
      timer: null,
      isShowBtn: true,
      regSuccess: false,
      codeValidate: true,
      pswLevel: 0,
      colors: ["#b1b3b8", "#b1b3b8", "#f56c6c", "#e6a23c", "#67c23a", "#79bbff"],
      verify: {
        show: false,
        isSend: false,
      },
    };
  },
  methods: {
    // 发送验证码
    sendCode () {
      let self = this;
      self.times = 60;
      if (self.emailButton != "发送验证码") {
        return;
      }
      self.isShowBtn = false;
      if (self.isTel === 1) {
        self.verificationCodeUUID = "";
        self.user.verificationCode = "";
        self.$api.auth.sendMessage({
          type: 2,
          phone: self.user.telPhone
        }).then(data => {
          self.$message.success("手机验证码发送成功，请注意查收");
          self.timer = setInterval(() => {
            self.times--;
            if (self.times === 0) {
              self.isShowBtn = true;
              clearInterval(self.timer);
            }
          }, 1000);

        }, err => {
          self.$message.error(err.msg);
          self.isShowBtn = true;
          clearInterval(self.timer);
        })
      } else {
        self.verificationCodeUUID = "";
        self.user.verificationCode = "";
        self.$api.auth.sendMailCode({
          type: 2,
          mail: self.user.email
        }).then(data => {
          self.$message.success("邮箱验证码发送成功，请注意查收");
          self.timer = setInterval(() => {
            self.times--;
            if (self.times === 0) {
              self.isShowBtn = true;
              clearInterval(self.timer);
            }
          }, 1000)

        }, err => {
          self.$message.error(err.msg);
          self.isShowBtn = true;
          clearInterval(self.timer);
        })
      }
    },
    getPwdLevel () {

    },
    forgetNext () {
      let self = this;
      if(self.step == 0) {
        if((self.user.telPhone || self.user.email) && self.user.verificationCode) {
          self.$api.auth.codeValidate({
            phone: self.isTel == 1 ? self.user.telPhone: self.user.email,
            code: self.user.verificationCode,
            type: self.isTel,
            smsType: 2
          }).then(data => {
            if(data) {
              self.step++;
            }
          })
          
        }
      }
      
    },
    saveAndLogin () {
      let self = this;
      let args = {
        type: 1,
        phone: self.user.telPhone,
        password: self.user.password,
        code: self.user.verificationCode
      }
      if(self.isTel == 1) {
        args.type = 1;
      }else {
        args.type = 2;
        args.phone = self.user.email;
      }
      self.$refs.form.validate(valid => {
        if (valid) {
          self.$api.auth.forgetPassword(args).then(data => {
            self.regSuccess = true;
            self.$message.success("密码修改成功，登录平台");
            self.$emit("hide")
          })
        }
      })
    },
    // 验证电话号码或邮箱
    telOrEmailValidation () {
      if (this.user.telOrEmail === "") {
        let errMsg = this.isTel === 1 ? "请输入手机号码" : "请输入邮箱地址";
        this.$message.error(errMsg);
        return false;
      }
      let formatCheck = this.isTel === 1 ? /^[1][3,4,5,7,8][0-9]{9}$/ : /^\w+@\w+\.\w+$/i;
      if (!this.user.telOrEmail.match(formatCheck)) {
        let errMsg = this.isTel === 1 ? "请输入正确的手机号码" : "请输入正确的邮箱地址";
        this.$message.error(errMsg);
        return false;
      }
      return true;
    },
    verificationCode () {
      if(this.isTel == 1 && !this.user.telPhone) {
        this.$message.error("请先输入手机号");
        return;
      }else if(this.isTel == 2 && !this.user.email){
        this.$message.error("请先输入邮箱");
        return;
      }
      this.verify.show = true;
      this.$refs.verify.generatorImage();
    },
    verificationCancel () {
      this.verify.show = false;
      this.$refs.verify.reset();
    },
    verificationConfirm () {
      this.sendCode();
    },
    // 切换找回方式
    changeTab (tabIndex) {
      if (this.isTel === tabIndex) {
        return;
      }
      this.isTel = tabIndex;
      this.verificationCodeUUID = "";
      this.emailDis = false;
      this.user.verificationCode = "";
      this.user.telPhone = "";
      this.user.email = "";
    },
    codeChange (val) {
      if(val && this.user.verificationCode)
        this.codeValidate = false;
      else 
        this.codeValidate = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.reg-window {
  width: 400px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 40px rgba(10, 15, 20, 0.4);
  position: relative;
  z-index: 40;
  pointer-events: auto;
  transition: all 0.2s linear;
  margin-bottom: 24px;
  color: #222;

  &.fadeout {
    transform: rotateY(-90deg);
    pointer-events: none;
  }

  .reg-tab-group {
    width: 100%;
    height: 50px;
    background: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(4px);
    border-radius: 8px 8px 0 0;
    display: flex;
    flex-direction: row;

    .reg-tab {
      width: auto;
      height: inherit;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      color: rgba(255, 255, 255, 0.6);
      cursor: pointer;

      &:hover {
        background: rgba(0, 0, 0, 0.1);
        color: rgba(255, 255, 255, 0.8);
      }

      &.active {
        background: #fff;
        color: #111;
        font-weight: bold;
      }
    }
  }

  .reg-body-group {
    width: 100%;
    background: #fff;
    padding: 30px;

    .reg-body {
      width: 100%;
    }
  }

  .reg-insert {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 12px;

    .reg-attr {
      font-size: 14px;
      margin-right: 8px;
      min-width: 56px;
    }

    .reg-val {
      width: 1%;
      flex: 1;
    }

    .reg-space {
      width: 120px;
      margin-left: 8px;
    }
  }

  .reg-file {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 12px 0;

    .reg-link {
      color: rgb(5, 120, 215);
      cursor: pointer;
      font-size: 14px;

      &:hover {
        color: rgb(180, 105, 20);
      }
    }
  }

  .reg-line {
    width: 100%;
  }
}

.reg-success {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 50;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #222;

  i {
    width: 128px;
    height: 128px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: bold;
    font-size: 80px;
    background: rgb(80, 220, 110);
    margin-bottom: 20px;
    border-radius: 50%;

    &:before {
      content: "";
      width: 64px;
      height: 36px;
      border-left: 8px solid #fff;
      border-bottom: 8px solid #fff;
      transform: rotate(-45deg);
    }
  }

  span {
    width: 100%;
    line-height: 1.5;
    padding: 0 16px;
    font-size: 18px;
    display: flex;
    justify-content: center;
  }
}
</style>
<style lang="scss">
.reg-window {
  .el-form-item {
    margin-bottom: 12px;
  }

  .el-form-item__error {
    position: unset;
    float: left;
    width: 100%;
    padding-left: 64px;
  }
}
</style>