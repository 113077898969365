<template>
  <div>
    <teleport to="body">
      <div class="verification" v-show="verifyShow">
        <div class="form">
          <div class="form-header">
            <h1>请输入图形验证码</h1>
            <div class="reg-line">
              <div class="reg-insert">
                <div class="reg-val">
                  <el-input size="large" class="reg-input" type="tel" v-model="code" maxlength="4" placeholder="请输入验证码" />
                </div>
                <div class="reg-space">
                  <el-image style="width: 100%;" :src="base64" title="点击刷新" @click="generatorImage()" />
                </div>
              </div>
            </div>
          </div>
          <div class="form-footer">
            <el-button type="info" @click="cancel()">取消</el-button>
            <el-button type="primary" @click="confirm()">确定</el-button>
          </div>
        </div>
      </div>

    </teleport>
  </div>
</template>

<script>
export default {
  name: "Verification",
  props: {
    verifyShow: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      code: "",
      base64: '',
      verBaseKey: ''
    }
  },
  created () {
    this.generatorImage();
  },
  methods: {
    //创建图形码
    generatorImage () {
      let self = this;
      self.$api.auth.generatorCaptcha().then(data => {
        self.base64 = data.imageBase64;
        self.verBaseKey = data.key;
      })
    },
    cancel () {
      this.$emit('cancel');
    },
    confirm () {
      let self = this;
      self.$api.auth.verifyCaptcha({ code: self.code, key: self.verBaseKey }).then(data => {
        if (data) {
          self.$emit('cancel');
          self.$emit('confirm');
        } else {
          self.$message.error("验证码错误");
          self.generatorImage();
        }

      })
    },
    reset () {
      this.code = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.verification {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(4px);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #222;

  .form {
    width: 100%;
    max-width: 300px;
    border-radius: 8px;
    overflow: hidden;

    h1 {
      width: 100%;
      font-size: 16px;
      font-weight: bold;
      margin: 0 0 10px;
      line-height: 1.5;
    }

    .reg-line {
      width: 100%;
    }

    .reg-insert {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 12px;

      .reg-attr {
        font-size: 14px;
        margin-right: 8px;
      }

      .reg-val {
        width: 1%;
        flex: 1;
      }

      .reg-space {
        width: 120px;
        margin-left: 8px;
      }
    }

    .form-header {
      width: 100%;
      padding: 30px 20px 20px;
      background: #fff;
    }

    .form-footer {
      width: 100%;
      display: flex;
      padding: 20px;
      background: #f1f3f5;

      .el-button {
        flex: 1;
      }
    }
  }
}
</style>
<style lang="scss">
.reg-input {
  input {
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, .2);
    color: rgba(0, 0, 0, .8) !important;
    font-size: 16px !important;
    transition: all .1s linear;

    &:hover,
    &:focus {
      border-color: rgb(0, 140, 255);
      color: #111 !important;
    }
  }
}
</style>