<template>
  <div class="login">
    <div class="help-btn" @click="jumpHelp()"><i class="icomoon-question"></i>帮助</div>
    <div class="login-btn" @click="loginOpen()" v-if="!showEnter">登录</div>
    <!-- <div class="resgister-btn"
         @click="registerOpen()" v-if="!showEnter">注册</div> -->
    <div class="login-btn" @click="enterPlatform()" v-if="showEnter">进入平台</div>
    <teleport to="body">
      <div class="login-area">
        <login-window @loginClose="close" @register="registerOpen" @forget="forgetOpen" :class="{ 'fadeout': !isLogin }"
          v-if="isLoginShow"></login-window>
        <register-window :class="{ 'fadeout': !isRegister }" v-if="isRegisterShow" @hide="close"></register-window>
        <forget-window :class="{ 'fadeout': !isForget }" v-if="isForgetShow"  @hide="close"></forget-window>
        <div class="login-close" @click="close()" :class="{ 'show': isLogin || isForget || isRegister }"></div>
      </div>
      <div class="login-shadow" :class="{ 'show': isLogin || isForget || isRegister }"></div>
    </teleport>
  </div>
</template>

<script>
import LoginWindow from './childComps/LoginWindow'
import RegisterWindow from './childComps/RegisterWindow'
import ForgetWindow from './childComps/ForgetWindow'

export default {
  name: "Login",
  components: {
    LoginWindow,
    RegisterWindow,
    ForgetWindow,
  },
  data () {
    return {
      showEnter: false,
      isLogin: false,
      isLoginShow: false,
      isForget: false,
      isForgetShow: false,
      isRegister: false,
      isRegisterShow: false,
    }
  },
  mounted () {
    let self = this;
    if(self.$store.state.user && self.$store.state.user.userInfo) 
      self.showEnter = true;
    self.$bus.on('showLogin', (m) => {
      self.loginOpen();
    })
    self.$bus.on('isLogin', (m) => {
      self.showEnter = m;
    })
  },
  beforeDestroy() {
    this.$bus.off('showLogin');
    this.$bus.off('isLogin');
  },
  methods: {
    close () {
      this.isLoginShow = this.isRegisterShow = this.isForgetShow = this.isLogin = this.isRegister = this.isForget = false;
    },
    loginOpen () {
      this.isLogin = this.isLoginShow = true;
    },
    registerOpen () {
      if (this.isLoginShow) {
        this.isLoginShow = this.isLogin = false;
        this.isRegister = this.isRegisterShow = true;
      } else {
        this.isRegister = this.isRegisterShow = true;
      }
    },
    forgetOpen () {
      if (this.isLoginShow) {
        this.isLoginShow = this.isLogin = false;
        this.isForget = this.isForgetShow = true;
      } else {
        this.isForget = this.isForgetShow = true;
      }
    },
    enterPlatform () {
      this.$router.push('Mgt');
    },
    jumpHelp () {
      window.open("https://vls-docs.blueearth.com.cn");
    },
  }
}
</script>

<style lang="scss" scoped>
$blue: rgb(0, 130, 255);
$blue-hover: rgb(0, 180, 255);

.login {
  height: 32px;
  display: flex;
  flex-direction: row;

  .login-btn,
  .success-btn,
  .resgister-btn,
  .help-btn {
    min-width: 80px;
    height: 100%;
    padding: 0 16px;
    font-size: 16px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 12px;
    cursor: pointer;
    transition: all 0.1s linear;
  }

  .login-btn {
    background: $blue;
    color: #fff;

    &:hover {
      background: $blue-hover;
    }
  }

  .resgister-btn {
    border: 1px solid $blue;
    color: $blue;

    &:hover {
      border-color: $blue-hover;
      color: $blue-hover;
    }
  }

  .help-btn {
    background: rgba(140, 150, 160, .6);

    &:hover {
      background: rgba(140, 150, 160, .8);
    }

    i {
      font-style: normal;
      margin-right: 8px;
    }
  }
}

.login-area {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  pointer-events: none;
  perspective: 1200px;
  perspective-origin: 50% 100px;
  transform-style: preserve-3d;
}

.login-shadow {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  pointer-events: auto;
  backdrop-filter: blur(4px);
  opacity: 0;
  pointer-events: none;
  transition: all 0.1s linear;

  &.show {
    opacity: 1;
    pointer-events: auto;
  }
}

.login-close {
  width: 40px;
  height: 40px;
  border: 2px solid #aaa;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  z-index: 10;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  opacity: 0;
  pointer-events: none;
  transition: all 0.2s linear;

  &.show {
    opacity: 1;
    pointer-events: auto;
  }

  &:before,
  &:after {
    content: "";
    width: 20px;
    height: 2px;
    background: #aaa;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -1px -10px;
    transition: all 0.1s linear;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }

  &:hover {
    border-color: #eee;

    &:before,
    &:after {
      background: #eee;
    }
  }
}
</style>
<style lang="scss">
.login-area {

  .login-input,
  .reg-insert {
    input {
      height: 40px;
      border: 1px solid #ddd;
      color: #333 !important;
      border-radius: 4px;
    }
  }

  .el-button {
    height: 40px;
  }
}
</style>