<template>
  <div>
    <frame-header :class="{'nor':!isCoverSwiper}"></frame-header>
    <div class="index">
      <el-scrollbar ref="scrollbar">
        <router-view></router-view>
        <frame-footer></frame-footer>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import FrameHeader from 'components/content/frame/header/Header'
import FrameFooter from 'components/content/frame/footer/Footer'

export default {
  name: 'Index',
  components: {
    FrameHeader,
    FrameFooter,
  },
  data () {
    return {
      isCoverSwiper: true
    }
  },
  mounted () {
    this.handleScroll()
  },
  methods: {
    handleScroll () {
      let self = this;
      let scrollbarEl = self.$refs.scrollbar.wrapRef,
        vh = document.querySelector('body').clientHeight - 80;
      if(scrollbarEl) {
        scrollbarEl.onscroll = () => {
          if (scrollbarEl.scrollTop > vh) self.isCoverSwiper = false
          else self.isCoverSwiper = true
        }
      }
      
    },

  }
}
</script>

<style lang="scss" scoped>
.index {
  width: 100vw;
  height: 100vh;
  background: #fafafa;
}
</style>