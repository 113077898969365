<template>
  <div class="login-frame">
    <h1>用户登录</h1>
    <div class="login-input pr">
      <el-input type="text" v-model="form.username" placeholder="手机号/邮箱" maxlength="320" />
      <div class="login-input-word" v-show="form.username.length">{{ form.username.length }}/320</div>
    </div>
    <div class="login-input">
      <el-input type="password" v-model="form.password" placeholder="登录密码" />
    </div>
    <el-button type="primary" round @click="login()" :loading="isLoading" class="login-btn">登录</el-button>
    <div class="login-link">
      <span @click="register()">立即注册</span>
      <span @click="forget()">忘记密码</span>
    </div>
    <div class="login-border"></div>
    <div class="login-other-group">
      <div class="login-other">
        <el-tooltip class="item" effect="dark" content="QQ帐号登录" placement="top">
          <img src="~assets/images/icons/icon-qq.svg" alt="QQ" @click="socialLogin('qq')">
        </el-tooltip>
        <el-tooltip class="item" effect="dark" content="微信帐号登录" placement="top">
          <img src="~assets/images/icons/icon-wx.svg" alt="微信" @click="socialLogin('weixin')">
        </el-tooltip>
        <el-tooltip class="item" effect="dark" content="新浪微博帐号登录" placement="top" v-if="false">
          <img src="~assets/images/icons/icon-sina.svg" alt="新浪">
        </el-tooltip>
      </div>
    </div>
    <div class="login-footer">
      当前版本：<span>V{{ version }}</span>
    </div>
  </div>
</template>

<script>
import _ from "underscore";
export default {
  name: "LoginWindow",
  data () {
    return {
      version: '',
      isLoading: false,
      form: {
        username: "",
        password: ""
      },
      user: {},
    }
  },
  created () {
    this.version = process.env.VUE_APP_VERSION;
    const self = this;
    self.keydownEvent = (e) => {
      var theEvent = e || window.event;
      var code = theEvent.keyCode || theEvent.which || theEvent.charCode;
      if (code == 13 && !self.isLoading) {
        self.login();
      }
    };
    document.addEventListener('keydown', this.keydownEvent);
  },
  methods: {
    loginClose () {
      document.removeEventListener('keydown', this.keydownEvent);
      this.$emit('loginClose');
    },
    socialLogin (type) {
      if (!_.contains(['qq'], type)) {
        this.$message({
          type: 'warning',
          message: '敬请期待'
        });
        return;
      }
      location.href = `/auth/socialSignIn/${type}`
    },
    login () {
      if (!this.form.username) {
        this.$message.error('用户名不能为空');
        return;
      }
      if (!this.form.password) {
        this.$message.error('密码不能为空');
        return;
      }
      this.isLoading = true;
      let self = this;
      this.$api.auth.signIn(this.form.username, this.form.password).then(res => {
        self.$store.dispatch('setToken', res.access_token).then(() => {
          self.loginClose();
          self.$router.push('Mgt');
        });
      }).finally(() => {
        self.isLoading = false;
      });
    },
    register () {
      this.$emit('register')
    },
    forget () {
      this.$emit('forget')
    }
  }
}
</script>

<style lang="scss" scoped>
$blue: rgb(0, 130, 255);
$blue-hover: rgb(0, 180, 255);

.login-frame {
  width: 420px;
  height: auto;
  position: relative;
  z-index: 10;
  background: #fafafa;
  padding: 42px 64px 72px;
  border-radius: 24px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  margin-bottom: 24px;
  pointer-events: auto;
  transition: all 0.2s linear;

  &.fadeout {
    transform: rotateY(90deg);
    pointer-events: none;
  }

  h1 {
    width: 100%;
    line-height: 1;
    float: left;
    margin-bottom: 24px;
    color: rgba(0, 0, 0, 0.8);
    text-align: center;
    font-size: 24px;
  }

  .login-input {
    width: 100%;
    float: left;
    margin-bottom: 16px;

    input {
      height: 40px;
    }

    .login-input-word {
      height: 40px;
      pointer-events: none;
      padding: 0 12px;
      float: right;
      margin: -40px 0;
      color: rgba(0, 0, 0, .2);
      display: flex;
      align-items: center;
      white-space: nowrap;
    }
  }

  .login-btn {
    width: 100%;
    float: left;
    margin-bottom: 16px;
    height: 40px;
    border-radius: 20px;
  }

  .login-border {
    width: 100%;
    height: 1px;
    float: left;
    background: rgba(0, 0, 0, 0.1);
    margin: 8px 0 24px;
  }

  .login-other-group {
    width: 100%;
    float: left;

    .login-other {
      width: 100%;
      float: left;
      display: flex;
      flex-direction: row;
      justify-content: center;

      img {
        width: 32px;
        height: 32px;
        cursor: pointer;
        margin: 0 10px;
      }
    }
  }

  .login-link {
    width: 100%;
    display: flex;
    flex-direction: row;
    float: left;
    justify-content: center;
    margin-bottom: 16px;

    span {
      height: 30px;
      padding: 0 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      color: rgba(0, 0, 0, 0.6);
      font-size: 14px;
      position: relative;

      &:hover {
        color: $blue;
      }

      &:before {
        content: "";
        height: 14px;
        width: 1px;
        position: absolute;
        right: 0;
        top: 8px;
        background: rgba(0, 0, 0, 0.1);
      }

      &:last-child {
        &:before {
          display: none;
        }
      }
    }
  }

  .login-footer {
    width: 100%;
    height: 32px;
    background: rgba(0, 0, 0, 0.04);
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0 24px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: rgba(0, 0, 0, 0.4);

    span {
      cursor: pointer;
      font-size: inherit;

      &:hover {
        color: $blue;
      }
    }
  }
}

:deep() {

  .login-input {

    &.pr {
      input {
        padding-right: 80px;
      }
    }

    input {
      height: 40px;
      font-size: 16px;
      padding: 0 12px;
    }
  }
}
</style>