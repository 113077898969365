<template>
  <div class="footer">
    ©2022-{{currentYear}} 上海蓝色星球科技股份有限公司
  </div>
</template>

<script>
export default {
  name: "Footer",
  data () {
    return {
      currentYear: new Date().getFullYear(),
    }
  },
}
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  height: 54px;
  background: rgba(10, 15, 20, 0.8);
  align-self: flex-end;
  float: left;
  color: rgba(255, 255, 255, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}
</style>